import {Component, EventEmitter, Input, Output} from '@angular/core';
  import { EnvService } from '../../env.service';

@Component({
  selector: 'app-workflow-sidebox-wrapper',
  template: `
    <micro-workflow-sidebox *axLazyElement="link, errorTemplate: error" [dataFromParent]="dataToChild"></micro-workflow-sidebox>
    <ng-template #error>
      <app-micro-error></app-micro-error>
    </ng-template>
  `,
  styles: [
  ]
})
export class WorkflowSideboxWrapperComponent {

  link = 'http://host.docker.internal:3007/main.js';
  @Input() dataToChild: string | undefined;
  @Output() dataFromChild = new EventEmitter<string>();
  listOfData: string[] = [];

  dataToParent($event: CustomEvent) {
    this.dataFromChild.emit($event.detail);
  }

  constructor(private env: EnvService) {
    if(env.enableDebug) {
      console.log('sidebox micro frontend configured URL' + env.workflowFrontEndURL);
    }
    //setup the URL for the micro-frontend
    if (env.workflowFrontEndURL){
      this.link = env.workflowFrontEndURL;
    }
  }

  ngOnInit(): void {
  }

}
