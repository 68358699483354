import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import { UserManagementWrapperComponent } from './user-management-wrapper.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [UserManagementWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [UserManagementWrapperComponent]
})
export class UserManagementWrapperModule { }
