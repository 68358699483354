import {Component, EventEmitter, Input, Output} from '@angular/core';
import { EnvService } from '../../env.service';

@Component({
  selector: 'app-admin-preferences-wrapper',
  template: `
    <micro-admin-preferences *axLazyElement="link, errorTemplate: error" [dataFromParent]="dataToChild"></micro-admin-preferences>
    <ng-template #error>
      <app-micro-error></app-micro-error>
    </ng-template>
  `,
  styles: [
  ]
})
export class AdminPreferencesWrapperComponent {

  link = 'http://host.docker.internal:3010/main.js';
  @Input() dataToChild: string | undefined;
  @Output() dataFromChild = new EventEmitter<string>();
  listOfData: string[] = [];

  dataToParent($event: CustomEvent) {
    this.dataFromChild.emit($event.detail);
  }

  constructor (private env: EnvService) {
    if(env.enableDebug) {
      console.log('admin preferences micro frontend configured URL' + env.adminManagementFrontEndURL);
    }
    //setup the URL for the micro-frontend
    if (env.adminManagementFrontEndURL){
      this.link = env.adminManagementFrontEndURL;
    }
  }

}
