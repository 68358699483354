import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-search',
  templateUrl: './nav-search.component.html',
  styleUrls: ['./nav-search.component.css']
})
export class NavSearchComponent implements OnInit {
  query: string = '';
  showDropDown: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  openDropDown = ():void =>{
    this.showDropDown = !this.showDropDown;
  }
}
