import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../../core/auth.service';
import { EnvService } from '../../env.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {

  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  canActivateProtectedRoutes$: Observable<boolean>;

  title = 'parent';
  data: any = 'something from parent!!!';
  authenticationData: any = this.authService.oauthServiceObject;
  pageTitle:string = 'Search';

  constructor(
    private authService: AuthService,
    private env: EnvService,
    private router: Router
  ) {
    //This variables are stored in env.js and will be in the root forlder of the application
    //once angular is compiled you can replace these values in that file to change any of its values
    if(env.enableDebug) {
      console.log('Debug mode enabled!');
    }
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isDoneLoading$ = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this.authService.canActivateProtectedRoutes$;
    router.events.subscribe((val) => {
      this.setPageTitle()
    });
  }

  ngOnInit(): void {
    this.setPageTitle();
  }

  login() { this.authService.login(); }
  logout() { this.authService.logout(); }
  refresh() { this.authService.refresh(); }
  reload() { window.location.reload(); }
  clearStorage() { localStorage.clear(); }

  logoutExternally() {
    window.open(this.authService.logoutUrl);
  }

  get hasValidToken() { return this.authService.hasValidToken(); }
  get accessToken() { return this.authService.accessToken; }
  get refreshToken() { return this.authService.refreshToken; }
  get identityClaims() { return this.authService.identityClaims; }
  get idToken() { return this.authService.idToken; }

  get email(): string {
    return this.authService.identityClaims
    ? (this.authService.identityClaims as any)['email']
    : '-';
  }

  get userName(): string {
    return this.authService.identityClaims
    ? (this.authService.identityClaims as any)['name']
    : '-';
  }

    /** 
   * The pictures needs to be mapped to an attribute
   * If using keycloak see https://stackoverflow.com/questions/42808008/how-to-get-current-user-profile-picture-with-keycloak
   */
  get picture(): string {
      return this.authService.identityClaims && (this.authService.identityClaims as any)['picture'] ? (this.authService.identityClaims as any)['picture']
      : 'assets/nav/profilepic.png';
  }
  
  showProfileMenu:boolean = false;
  toggleProfileMenu() {
    this.showProfileMenu = !this.showProfileMenu;
  }

  setPageTitle() {
    const route = this.router.url.split('/')
    let specificRoute = "";
    if (route[1] == "basics") {
      specificRoute = route[2];
    }
    switch (specificRoute) {
      case 'search':
        this.pageTitle = "Search";
        break;
      case 'user-management':
        this.pageTitle = "User Profile";
        break;
      case 'preferences':
        this.pageTitle = "User Preferences";
        break;
      case 'admin-management':
        this.pageTitle = "Admin Preferences";
        break;
      default:
        this.pageTitle = " ";
    }
  }

  @Output() sidebarButtonClicked = new EventEmitter<string>();
  clickHamburgerMenu() {
    this.sidebarButtonClicked.emit();
  }

  @Output() logoutClicked = new EventEmitter<string>();
  clickLogout() {
    this.logoutClicked.emit();
  }
}
