import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import { UserPreferencesWrapperComponent } from './user-preferences-wrapper.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [UserPreferencesWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [UserPreferencesWrapperComponent]
})
export class UserPreferencesWrapperModule { }
