import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav/top-nav.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { NavSearchComponent } from './top-nav/nav-search/nav-search.component';
import { FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { FallbackComponent } from '../fallback.component';
import { ShouldLoginComponent } from '../should-login.component';



@NgModule({
  declarations: [
    TopNavComponent,
    SideNavComponent,
    NavSearchComponent,
    // FallbackComponent,
    // ShouldLoginComponent
  ],
  exports: [
    TopNavComponent,
    SideNavComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'basics/home', pathMatch: 'full' },
      // Note: this way of module loading requires this in your tsconfig.json: "module": "esnext"
      { path: 'basics', loadChildren: () => import('../feature-basics/basics.module').then(m => m.BasicsModule) },
      { path: 'extras', loadChildren: () => import('../feature-extras/extras.module').then(m => m.ExtrasModule) },
      { path: 'should-login', component: ShouldLoginComponent },
      { path: '**', component: FallbackComponent },
  ], { relativeLinkResolution: 'legacy' }),
  ]
})
export class NavigationModule { }
