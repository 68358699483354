import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-admin-wrapper',
  template: `
    <micro-app-admin *axLazyElement="link, errorTemplate: error" [dataFromParent]="dataToChild"></micro-app-admin>
    <ng-template #error>
      <app-micro-error></app-micro-error>
    </ng-template>
  `,
  styles: []
})
export class AdminWrapperComponent{
  link = 'http://host.docker.internal:3005/main.js';
  @Input() dataToChild: string | undefined;
  @Output() dataFromChild = new EventEmitter<string>();
  listOfData: string[] = [];

  dataToParent($event: CustomEvent) {
    this.dataFromChild.emit($event.detail);
  }

}
