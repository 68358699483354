import { NgModule } from '@angular/core';

import { ApiService } from './api.service';
import { MicroErrorComponent } from './micro-error/micro-error.component';

@NgModule({
  providers: [
    ApiService,
  ],
  declarations: [
    MicroErrorComponent
  ],
  exports: [
    MicroErrorComponent
  ]
})
export class SharedModule { }
