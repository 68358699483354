import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyElementsModule } from '@angular-extensions/elements';
import { WorkflowBoxesWrapperComponent } from './workflow-boxes-wrapper.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [WorkflowBoxesWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [WorkflowBoxesWrapperComponent]
})
export class WorkflowBoxesWrapperModule { }
