import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import {ChildWrapperComponent} from './child-wrapper.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [ChildWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ChildWrapperComponent]
})
export class ChildWrapperModule { }