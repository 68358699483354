import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import { TaskActionWrapperComponent } from './task-action-wrapper.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [TaskActionWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TaskActionWrapperComponent]
})
export class TaskActionWrapperModule { }
