import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EnvService  {

  //these values are the default when src/env.js is not available
  public apiUrl = '';
  public enableDebug = true;
  public workflowFrontEndURL = 'http://host.docker.internal:3003/main.js';
  public searchFrontEndURL = 'http://host.docker.internal:3008/main.js';
  public analyticsFrontEndURL = 'http://host.docker.internal:3007/main.js';
  public userManagementFrontEndURL = 'http://host.docker.internal:3009/main.js';
  public adminManagementFrontEndURL = 'http://host.docker.internal:3010/main.js';
  public taskActionFrontEndURL = 'http://host.docker.internal:3011/main.js';
  public issuer = null;
  public clientId= null;
  public dummyClientSecret=null;
  public scope=null;

  // loadConfig() {
  //   return firstValueFrom(this.http.get<EnvService>('./config.json'));
  // }

}

