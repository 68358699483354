import {APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppMenuComponent } from './app-menu.component';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FallbackComponent } from './fallback.component';
import { ShouldLoginComponent } from './should-login.component';
import {ChildWrapperModule} from './webcomponents/child-wrapper/child-wrapper.module';
import { NavigationModule } from './navigation/navigation.module';
import {LazyElementsModule} from '@angular-extensions/elements';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchWrapperModule } from './webcomponents/search-wrapper/search-wrapper.module';
import { AdminWrapperModule } from './webcomponents/admin-wrapper/admin-wrapper.module';
import { EnvServiceProvider } from './env.service.provider';
import { WorkflowWrapperModule } from './webcomponents/workflow-wrapper/workflow-wrapper.module';
import { WorkflowBoxesWrapperModule } from './webcomponents/workflow-boxes-wrapper/workflow-boxes-wrapper.module';
import { WorkflowSideboxWrapperModule } from './webcomponents/workflow-sidebox-wrapper/workflow-sidebox-wrapper.module';
import { UserManagementWrapperModule } from './webcomponents/user-management-wrapper/user-management-wrapper.module';
import { UserPreferencesWrapperModule } from './webcomponents/user-preferences-wrapper/user-preferences-wrapper.module';
import { UserInboxWrapperModule } from './webcomponents/user-inbox-wrapper/user-inbox-wrapper.module';
import { AdminPreferencesWrapperModule } from './webcomponents/admin-preferences-wrapper/admin-preferences-wrapper.module';
import { TaskActionWrapperModule } from './webcomponents/task-action-wrapper/task-action-wrapper.module';
import { AppConfigService } from './app-config-service.service';
@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    FallbackComponent,
    ShouldLoginComponent
    ],
  providers: [EnvServiceProvider
    ,{provide : APP_INITIALIZER, multi : true, deps : [AppConfigService],  useFactory : (appConfigService : AppConfigService) =>  () => appConfigService.loadAppConfig()}
  ],
  imports: [
    LazyElementsModule,
    ChildWrapperModule,
    SearchWrapperModule,
    AdminWrapperModule,
    WorkflowWrapperModule,
    WorkflowBoxesWrapperModule,
    WorkflowSideboxWrapperModule,
    UserManagementWrapperModule,
    UserPreferencesWrapperModule,
    UserInboxWrapperModule,
    AdminPreferencesWrapperModule,
    TaskActionWrapperModule,
    BrowserModule,
    NavigationModule,
    CoreModule.forRoot(),
    RouterModule.forRoot([
    { path: '', redirectTo: 'basics/home', pathMatch: 'full' },
    // Note: this way of module loading requires this in your tsconfig.json: "module": "esnext"
    { path: 'basics', loadChildren: () => import('./feature-basics/basics.module').then(m => m.BasicsModule) },
    { path: 'extras', loadChildren: () => import('./feature-extras/extras.module').then(m => m.ExtrasModule) },
    { path: 'should-login', component: ShouldLoginComponent },
    { path: '**', component: FallbackComponent },
], { relativeLinkResolution: 'legacy' }),
    FontAwesomeModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
