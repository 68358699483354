import {Component, EventEmitter, Input, Output} from '@angular/core';
import { EnvService } from '../../env.service';

@Component({
  selector: 'app-task-action-wrapper',
  template: `
  <micro-task-action *axLazyElement="link, erorrTemplate: error" [dataFromParent]="dataToChild"></micro-task-action>
  <ng-template #error>
    <app-micro-error></app-micro-error>
  </ng-template>
  `,
  styles: [
  ]
})
export class TaskActionWrapperComponent {

  link = 'http://host.docker.internal:3008/main.js';
  @Input() dataToChild: string | undefined;
  @Output() dataFromChild = new EventEmitter<string>();
  listOfData: string[] = [];

  dataToParent($event: CustomEvent) {
    this.dataFromChild.emit($event.detail);
  }

  constructor (private env: EnvService) {
    if(env.enableDebug) {
      console.log('task action micro frontend configured URL' + env.taskActionFrontEndURL);
    }
    //setup the URL for the micro-frontend
    if (env.taskActionFrontEndURL){
      this.link = env.taskActionFrontEndURL;
    }
  }

}
