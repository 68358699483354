import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import {AdminWrapperComponent} from './admin-wrapper.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [AdminWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AdminWrapperComponent]
})
export class AdminWrapperModule { }
