import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-micro-error',
  templateUrl: './micro-error.component.html',
  styleUrls: ['./micro-error.component.css']
})
export class MicroErrorComponent implements OnInit {

  constructor() { }

  @ViewChild('microerror') element:any; 
  
  ngOnInit(): void {
    // console.log(document.querySelector(".card-container"));
    
  }

  ngAfterViewInit(): void {
    const height = this.element.nativeElement.offsetHeight;
    const robot = this.element.nativeElement.querySelector(".robot");
    robot.height = height/2;
    robot.width = height/2;
  }

}
