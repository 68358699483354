import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {LazyElementsModule} from '@angular-extensions/elements';
import { AdminPreferencesWrapperComponent } from './admin-preferences-wrapper.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [AdminPreferencesWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AdminPreferencesWrapperComponent]
})
export class AdminPreferencesWrapperModule { }
