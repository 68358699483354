import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { EnvService} from './env.service';
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: any;
  private http : HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  loadAppConfig() {
     //this.appConfig = firstValueFrom(this.http.get<EnvService>('/assets/config/config.json'));
     this.http.get<EnvService>('/assets/config/config.json').subscribe(data => {
        console.log(data);
        this.appConfig = data;
      })
     return this.appConfig;
  }

  get apiUrl() : string {
    return this.appConfig.apiUrl;
  }

  get enableDebug() : boolean {
    return this.appConfig.enableDebug;
  }

  get workflowFrontEndURL() : string {
    return this.appConfig.workflowFrontEndURL;
  }

  get searchFrontEndURL() : string {
    return this.appConfig.searchFrontEndURL;
  }

  get analyticsFrontEndURL() : string {
    return this.appConfig.analyticsFrontEndURL;
  }

  get userManagementFrontEndURL() : string {
    return this.appConfig.userManagementFrontEndURL;
  }

  get adminManagementFrontEndURL() : string {
    return this.appConfig.adminManagementFrontEndURL;
  }

  get taskActionFrontEndURL() : string {
    return this.appConfig.taskActionFrontEndURL;
  }

  get issuer() : string {
    return this.appConfig.issuer;
  }

  get clientId() : string {
    return this.appConfig.clientId;
  }
  get dummyClientSecret() : string {
    return this.appConfig.dummyClientSecret;
  }
  get scope() : string {
    return this.appConfig.scope;
  }



}
