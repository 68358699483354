<nav class="navbar navbar-expand-sm navbar-light bg-white d-flex flex-row justify-content-between">
  <ul class="navbar-nav">
    <i class="fa fa-bars" (click)="clickHamburgerMenu();"></i>
    <div class="page-title">{{ pageTitle }}</div>
  </ul>
  <div class="d-flex flex-row">
    <app-nav-search></app-nav-search>

    <div class="ms-5">
      <button class="btn btn-sm btn-default" (click)="login()" *ngIf="(isAuthenticated$ | async) === false">Log
        in</button>

      <div class="profile-menu-container" *ngIf="isAuthenticated$ | async" (click)="toggleProfileMenu();">
        <img [src]="picture" class="profile-picture me-2" alt="Profile Picture" referrerpolicy="no-referrer">
        <span id="email">{{userName}}</span>
        <span class="fa fa-chevron-down ms-2"></span>

        <ul class="profile-menu" *ngIf="showProfileMenu">
          <li>{{ email }}</li>
          <li routerLink="basics/user-management">
            Go to Profile
            <span class="fas fa-external-link-alt ms-2"></span>
          </li>
          <li routerLink="basics/preferences">User Preferences</li>
          <li (click)="clickLogout();">Log Out</li>
        </ul>
      </div>
    </div>
  </div>

  <!-- <button *ngIf="isAuthenticated$ | async" href="#" (click)="logout()" class="btn btn-link">(log out)</button> -->
</nav>
