<div class="card-container micro-error" #microerror>
  <!-- <div>
    <i class="fa fa-exclamation-circle">
      <div class="test"></div>
    </i>
    <div class="big-words">
      Oops! Something went wrong.
    </div>
    <div class="small-words">
      Please try again later.
    </div>
  </div> -->
  <div>
    <div class="words">
      <!-- <div class="logo-container"> -->
        <img class="logo" src="assets/gallery/conseqta.png">
      <!-- </div> -->
      <div class="big-words">
        Oops! Something went wrong.
      </div>
      <div class="small-words">
        Please try again later.
      </div>
    </div>
    <img class="robot" src="assets/gallery/broken-robot.png" height="100px">
    <!-- <img class="robot" src="assets/gallery/robot_in_shower.png" height="100px"> -->
  </div>
</div>