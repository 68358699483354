import { HttpClientModule, HttpClient, HttpXhrBackend } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { authConfig } from './auth-config';
import { AuthGuardWithForcedLogin } from './auth-guard-with-forced-login.service';
import { AuthGuard } from './auth-guard.service';
import { authModuleConfig } from './auth-module-config';
import { AuthService } from './auth.service';
import { EnvService } from '../env.service';
import { AppConfigService} from '../app-config-service.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthGuardWithForcedLogin
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    //override values from the configuraiton service, since the EnvSerivce instance doesn't exists yet to be
    //injected from the provider we need to read the configuration directly here

    const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

    let appConfigService = new AppConfigService(httpClient);
    appConfigService.loadAppConfig();

    // Read environment variables from browser window
    const browserWindow = window || {};
    const browserWindowEnv = browserWindow['__env' as keyof Window] || {};
    let env = new EnvService();

    // if (appConfigService.enableDebug){
    //   console.log("Loading CoreModule...")
    // }

    for (const key in browserWindowEnv) {
      if (browserWindowEnv.hasOwnProperty(key)) {
        (env as any)[key] = window['__env' as keyof Window][key];
      }
    }
    //override from configuration for security
    if (env.clientId && env.issuer && env.dummyClientSecret){
      authConfig.clientId = env.clientId;
      authConfig.issuer = env.issuer;
      authConfig.dummyClientSecret = env.dummyClientSecret;
      if (env.scope){
        authConfig.scope = env.scope;
      }
    }
    if (env.enableDebug){
      console.log('environment configured for security: ');
      console.log('    client: ' +  authConfig.clientId);
      console.log('    issuer: ' +  authConfig.issuer);
      console.log('    secret: ' +  authConfig.dummyClientSecret);
      console.log('    scope: ' +  authConfig.scope);
    }

    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
