import {Component, EventEmitter, Input, Output} from '@angular/core';
import { EnvService } from '../../env.service';

@Component({
  selector: 'app-child-wrapper',
  template: `
    <micro-app *axLazyElement="link, errorTemplate: error" [dataFromParent]="dataToChild"></micro-app>
    <ng-template #error>
      <app-micro-error></app-micro-error>
    </ng-template>
  `,
  styles: []
})
export class ChildWrapperComponent {
  link = 'http://host.docker.internal:3000/main.js';
  @Input() dataToChild: string | undefined;
  @Output() dataFromChild = new EventEmitter<string>();
  listOfData: string[] = [];

  dataToParent($event: CustomEvent) {
    this.dataFromChild.emit($event.detail);
  }
  /**
   * 
   * @param env Injects the environment configuration. This is stored in env.js and can be replaced after the code is compiled from the root folder of the application
   */
  constructor (private env: EnvService) {
    if(env.enableDebug) {
      console.log('Debug mode enabled!');
    }
  }
}
