import {Component, EventEmitter, Input, Output} from '@angular/core';
import { EnvService } from '../../env.service';

@Component({
  selector: 'app-workflow-wrapper',
  template: `
    <micro-workflow-inbox *axLazyElement="link, errorTemplate: error" [dataFromParent]="dataToChild" (expandButtonClick)="handleExpandButton($event)" (gearClicked)="handleInboxOptions($event)" (docClicked)="handleDocClick($event)"></micro-workflow-inbox>
    <ng-template #error>
      <div style="height: 100%; min-height: 400px">
        <app-micro-error></app-micro-error>
      </div>
    </ng-template>
  `,
  styles: []
})
export class WorkflowWrapperComponent  {

  link = 'http://host.docker.internal:3003/main.js';
  @Input() dataToChild: string | undefined;
  @Output() expandClicked = new EventEmitter<string>();
  @Output() inboxOptionsClicked = new EventEmitter<string>();
  @Output() docClick = new EventEmitter<string>();
  listOfData: string[] = [];

  // dataToParent($event: CustomEvent) {
  //   this.expandClicked.emit($event.detail);
  // }

  handleDocClick(num:any) {
    this.docClick.emit(num)
  }

  constructor (private env: EnvService) {
    if(env.enableDebug) {
      console.log('workflow micro frontend configured URL' + env.workflowFrontEndURL);
    }
    //setup the URL for the micro-frontend
    if (env.workflowFrontEndURL){
      this.link = env.workflowFrontEndURL;
    }
  }

  handleExpandButton(e:any) {
    this.expandClicked.emit(e);
  }

  handleInboxOptions(e:any) {
    this.inboxOptionsClicked.emit(e);
  }


}
