<div class="main-contain d-flex">
  <div class="sidebar-container position-fixed start-0 top-0 h-100">
    <app-side-nav [collapsed]="sideBarCollapsed"></app-side-nav>
  </div>
  <div class="topbar-container position-fixed top-0 end-0" [ngClass]="{'topbar-container-collapsed': !sideBarCollapsed}">
  <app-top-nav (sidebarButtonClicked)="toggleSidebarExpansion()" (logoutClicked)="handleLogout();"></app-top-nav>
  </div>  
  <div class="container-fluid body-container" [ngClass]="{'body-container-collapsed': !sideBarCollapsed}">
    <!-- <app-menu></app-menu> -->
    
    <router-outlet></router-outlet>
   
    <!-- <div class="container-fluid pt-2" style="background-color: #F1F2F5;">
      <h1>Welcome</h1>
      <p>This is part of the app.component. Below is the router outlet.</p>
      <hr>
      <hr>
      <p>You can <a routerLink="/url-without-route">go to a url without a route</a> to see the fallback route.</p>
      <hr>
      <app-analytics-gauge  [dataToChild]="data"></app-analytics-gauge>
      <p>
        <button class="btn btn-success mr-1 me-1" (click)='login()'>login</button>
        <button class="btn btn-primary mr-1 me-1" (click)='logout()'>logout</button>
        <button class="btn btn-link mr-1 me-1" (click)='logoutExternally()'>logout externally...</button>
      </p>
      <p>
        <button class="btn btn-warning mr-1 me-1" (click)='refresh()'>force silent refresh</button>
        <button class="btn btn-secondary mr-1 me-1" (click)='reload()'>reload page</button>
        <button class="btn btn-danger mr-1 me-1" (click)='clearStorage()'>clear storage</button>
      </p>
      <hr>
      <table class="table table-bordered table-sm table-props">
        <tr><th>IsAuthenticated</th><td><code id="isAuthenticated">{{isAuthenticated$ | async}}</code></td></tr>
        <tr><th>HasValidToken</th><td><code id="hasValidToken">{{hasValidToken}}</code></td></tr>
        <tr><th>IsDoneLoading</th><td><code id="isDoneLoading">{{isDoneLoading$ | async}}</code></td></tr>
        <tr><th>CanActivateProtectedRoutes</th><td><code id="canActivateProtectedRoutes">{{canActivateProtectedRoutes$ | async}}</code></td></tr>
        <tr><th>IdentityClaims</th><td class="pre"><code id="identityClaims">{{identityClaims | json}}</code></td></tr>
        <tr><th>RefreshToken</th><td><code class="break-all">{{refreshToken}}</code></td></tr>
        <tr><th>AccessToken</th><td><code class="break-all">{{accessToken}}</code></td></tr>
        <tr><th>IdToken</th><td><code class="break-all">{{idToken}}</code></td></tr>
      </table>
    </div>
  </div> -->
</div>