import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyElementsModule } from '@angular-extensions/elements';
import { WorkflowSideboxWrapperComponent } from './workflow-sidebox-wrapper.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [WorkflowSideboxWrapperComponent],
  imports: [
    CommonModule,
    LazyElementsModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [WorkflowSideboxWrapperComponent]
})
export class WorkflowSideboxWrapperModule { }
