<div class="nav-search mr-4 me-4">
  <i class="fa fa-search" (click)="openDropDown()"></i>
  <input class="nav-search-input" [(ngModel)]="query" placeholder="What are you looking for today?">
</div>
<div class="nav-search-dd position-fixed" *ngIf="showDropDown">
  <div class="results-nav-header ms-2 mt-2 mb-2">search results for "{{query}}"</div>
  <ul class="nav-bar-results m-0 p-0">
  <li class="d-flex flex-row navbar-result-row pt-2 pb-2">
    <div class="ms-2">Document Name</div>
    <div class="d-flex flex-row ms-3">Created: <div class="ms-1">1/22/2022</div>
    </div>
  </li>
  <li class="d-flex flex-row navbar-result-row pt-2 pb-2">
    <div class="ms-2">Document Name</div>
    <div class="d-flex flex-row ms-3">Created: <div class="ms-1">1/22/2022</div>
    </div>
  </li>
  <li class="d-flex flex-row navbar-result-row pt-2 pb-2">
    <div class="ms-2">Document Name</div>
    <div class="d-flex flex-row ms-3">Created: <div class="ms-1">1/22/2022</div>
    </div>
  </li>
  <li class="d-flex flex-row navbar-result-row pt-2 pb-2">
    <div class="ms-2">Document Name</div>
    <div class="d-flex flex-row ms-3">Created: <div class="ms-1">1/22/2022</div>
    </div>
  </li>
</ul>
<div class="search-button-top-bar m-auto mt-4 d-flex justify-content-center align-items-center" routerLinkActive="active" routerLink="basics/search" (click)="openDropDown()">
  <div class="">View All Results</div>
</div>

</div>